<!-- eslint-disable vue/no-textarea-mustache -->
<template>
    <div class="m-2">
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
        <div class="row">
  <div class="col-12">
    <div class="row">
      <label style="text-align: end; color: #00979e; cursor: pointer;" @click="showNewer" v-if="hasPrevPage">Newer</label>
      <div v-if="visibleRecords.length" class="col-lg-3 col-md-3 col-sm-3 col-6" v-for="record  in visibleRecords" :key="record.id" :id="record.id">
        <a class="nav-link bg-color text-white text-center cursor-pointer my-1 dateButton" id="date1-tab" data-toggle="pill new" 
        @click="Consultation(record,index)" role="tab" aria-controls="date-1" aria-selected="false">{{ formatDate(record.consultation_date) }}</a>
    <h1 v-if="!record.consultation_date">No records available</h1> 
    </div>
    <div v-else class="text-align text-center">
     <h2 style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63); font-size: 25px;">No records available</h2>
     </div>
      <div>
        <label style="float: right; color: #00979e; cursor: pointer;" @click="showOlder" v-if="hasNextPage">Older</label>
      </div>
    </div>
  </div>
</div>
        <hr class="hr-border" />
        <div v-if="previousConsultation">
            <!-- <div v-if="previousConsultation === famRecord"> -->
                <div class="row mt-3">
                    <div class="col-md-12 col-12">
                        <h3 class="text-center "> {{ formatDate(previousConsultation.consultation_date) }}</h3>
                    </div>
                </div>
                <div>
                    <b class="medi-recordDoctorName">{{ previousConsultation.doctor_name }}</b>
                    <p class="medi-recordCaseSheet mb-0">Case sheet</p>
                </div>
                <div class="row">
                    <div class="col-md-12 col-12">
                        <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <form class="caseSheetTextAreas">
                                    <textarea class="doctorWritenPlace" readonly>{{ previousConsultation.caseSheet }}</textarea>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12">
                        <p class="medi-recordCaseSheet">Prescription</p>
                    </div>
                </div>
                <!-- PRESCRIPTION FOR DESKTOP AND TABLET  -->
                <div  v-if="previousConsultation.upload_prescription">
                <div class="row mb-3">
                    <div>
                    <img class="mx-auto d-block"  :src="previousConsultation.upload_prescription" 
                    :style="{ transform: 'rotate(' + previousConsultation.rotationAngle  + 'deg)' }"/>
                    </div>
                </div>
                <button style="float: right;"  class="btn btn-color text-white mb-3 mx-3" @click="rotateImage(previousConsultation)">Rotate</button>
                </div>
                <div  class="d-none d-md-block mb-3" v-else>
                    <h4 style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">No prescription </h4>
                </div>

            <!-- </div> -->
        </div>
    </div>
    <records :familyRecords="familyRecords"></records>
</template>
<script>
import records from '../records.vue';
import axios from 'axios';
export default {
    components: {
        records
    },
    props: ['upValue', 'upFamily','getUserRecord'],
    data() {
        return {
            addSchedule1: false,
            addSchedule2: false,
            addSchedule3: false,
            previousConsultation: null,
            selectFamilyRecord: false,
            familyRecords: [],
            uploadRecord: [],
            currentPage: 1,
            recordsPerPage: 8,
            showRecord: false,
        }
    },
    created: async function () {
        if(this.upValue){
            if(this.getUserRecord?.doctor_name){
                this.Consultation(this.getUserRecord);
            }
    }
        this.uploadData();
    },
    computed: {
        paginatedData() {
    const startIndex = (this.currentPage - 1) * this.recordsPerPage;
    const endIndex = startIndex + this.recordsPerPage;
    return this.uploadRecord.slice(startIndex, endIndex);
  },
  hasPrevPage() {
    return this.currentPage > 1;
  },
  hasNextPage() {
    return this.currentPage < this.totalPages;
  },
  totalPages() {
    return Math.ceil(this.uploadRecord.length / this.recordsPerPage);
  },
  visibleRecords() {
    return this.paginatedData;
  }
},
mounted() {
  this.setRecordsPerPage(); // You may also want to call this method when the window is resized to adapt to the screen width changes.
},
    methods: {
        rotateImage(consultation) {
            consultation.rotationAngle += 90;
    },

        setRecordsPerPage() {
    if (window.innerWidth <= 575) {
      this.recordsPerPage = 4; // Set 4 records per page for smaller screens
    } else {
      this.recordsPerPage = 8; // Set 8 records per page for larger screens
    }
  },
        formatDate(dateString) {
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
        Consultation(index,) {
            this.previousConsultation = index;
        },
        uploadData() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/upload-records`)
                .then((response) => {
                    response.data.map((filterData)=>{
                         if(filterData?.user_id.id === this.upValue.id && filterData?.family_id?.id === this.upFamily?.id){
                            filterData.rotationAngle=0
                                this.uploadRecord.push(filterData)
                            }
                        })
                })
                .catch(function (error) {
                   return error
                });
        },
        showNewer() {
        this.currentPage--;
        },
        showOlder() {
        this.currentPage++;
        },
    }
}
</script>
<style>
.record-div{
    width: 25%;
    
}
.recordsAlign{
    display: contents;
}
.medi-recordDoctorName {
    margin-top: 3rem;
    margin-bottom: 0px;
}

.medi-recordCaseSheet {
    margin-top: 1rem;
    /* margin-bottom: 0px; */
}

img.mx-auto.d-block {
    margin-right: auto !important;
    margin-left: auto !important;
    width: auto;
}

textarea.doctorWritenPlace {
    width: 100%;
    height: 200px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 20px;
}

form.caseSheetTextAreas {
    width: 75%;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width:991px) {
    .medi-recordDoctorName {
        margin-top: 1.5rem;
    }

    img.mx-auto.d-block {
        padding: 15px;
    }
}

@media screen and (max-width:769px) {
    form.caseSheetTextAreas {
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .dateButton {
        font-size: 15px;
    }
}

@media screen and (max-width:576px) {
    .medi-recordDoctorName {
        margin-top: 0.5rem;
    }

    .medi-recordCaseSheet {
        margin-top: 0.5rem;
    }
}
</style>
