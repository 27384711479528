<template>
    <div class="share-with-me-section">
        <div>
            <h5 class="text-color">Records shared with me</h5>
        </div>

        <div class="accordion box-shadow" id="accordionShareWithMe">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button bg-light-orange text-dark border-orange-color" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapseSharepatient1" aria-expanded="false"
                        aria-controls="collapseSharepatient1">
                        <strong>Patient 1</strong>
                    </button>
                </h2>
                <div id="collapseSharepatient1" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionShareWithMe">
                    <div class="accordion-body p-4">
                        <patientRecord></patientRecord>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed bg-light-orange text-dark border-orange-color" type="button"
                        data-bs-toggle="collapse" data-bs-target="#collapseSharepatient2" aria-expanded="false"
                        aria-controls="collapseSharepatient2">
                        <strong>Patient 2</strong>
                    </button>
                </h2>
                <div id="collapseSharepatient2" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionShareWithMe">
                    <div class="accordion-body p-4">
                        <patientRecord></patientRecord>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import patientRecord from './patient-record/patient-record.vue'
export default {
    components: {
        patientRecord
    },
    data() {
        return {

        };
    },
    created: function () {
    },
    methods: {

    },
}

</script>
<style>
.image-fix {
    border: 3px solid #00979e;
  height: 100%;
  padding: 5px;
}

.text-center.name-fix {
    text-align: center !important;
    font-size: large;
    font-weight: bold;
}

img.img-camera.icon {
    float: right;
  width: 30px;
  margin:5px;
}

img.img-men.icon {
    width: 50%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

img.img-women.icon {
    width: 50%;
    box-sizing: border-box;
    margin-left: 4.5rem;
    margin-top: 10px;
}
</style>