<template>
  <div class="page-content w-100 ">
    <div class="row">
      <div class="col-sm-12">
        <p class="text-center page-heading">Medical Records</p>
      </div>
    </div>
    <div class="left-space-desktop">
  <div class="row">
  <div class="col-sm-12 usersStyle">
    <div class="family-section">
      <h4 class="myfamilyTab">My family</h4>
      <div class="text-border"></div>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" id="nav-myfamily" role="tabpanel" aria-labelledby="nav-myfamily-tab">
          <Family></Family>
        </div>
        <div class="tab-pane fade" id="nav-others" role="tabpanel" aria-labelledby="nav-others-tab">
          <Others></Others>
        </div>
    </div>
    <div class="row" style="display: flex;margin-left: 10px; margin-right: 10px;">
      <div class="col-lg-3 col-md-3 col-6 mt-3" v-for="(value, index) in userRecord" :key="index"
        @click="openUserRecords(value)">
        <div class="image-fix">
          <div>
            <div class="text-center name-fix">{{ value.firstName }}</div>
            <img class="img-women icon" src="@/assets/images/Male.png" v-if="value.gender === 'Male'" />
              <img class="img-women icon" src="@/assets/images/Female.png" v-else-if="value.gender === 'Female'" />
            <img class="img-women icon" src="@/assets/images/nogender.png" v-else-if="value.gender === 'Others'" />
            </div>
          <div>
            <img class="img-camera icon" src="@/assets/images/camera.png" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-6 mt-3" v-for="(value, index) in famData" :key="index"
        @click="openFamilyRecords(value)">
        <div class="image-fix">
          <div>
            <div class="text-center name-fix">{{ value.firstName }}</div>
            <img class="img-women icon" src="@/assets/images/Male.png" v-if="value.gender === 'Male'" />
              <img class="img-women icon" src="@/assets/images/Female.png" v-else-if="value.gender === 'Female'" />
            <img class="img-women icon" src="@/assets/images/nogender.png" v-else-if="value.gender === 'Others'" />
          
          </div>
          <div>
            <img class="img-camera icon" src="@/assets/images/camera.png" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-6 mt-3" v-if="!closeAddPatient">
                <div class="image-fix">
                    <div class="text-center name-fix">Add family member</div>
                    <img class="img-women icon" src="@/assets/images/plus-add.png" @click="addPatient()" />
                </div>
            </div>
    </div>
  <div v-if="closeAddPatient" class="mx-3">
    <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group ">
                <label for="" class="mt-3">First name<span class="star">*</span></label>
                <input type="text" class="form-control" v-model="firstName" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
                <label for="" class="mt-3">Last name<span class="star">*</span></label>
                <input type="text" class="form-control" v-model="lastName" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
            </div>
        </div>
      </div>
      <div class="row">
          <div class="col-6">
              <div class="form-group">
                  <label for="" class="mt-3">Age in years<span class="star">*</span></label>
                  <input type="number" class="form-control" v-model="age" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
              </div>
          </div>
        <div class="col-6">
            <div class="form-group">
                <label for="" class="mt-3">Gender<span class="star">*</span></label>
                <div>
                    <select class="form-control" name="gender" id="gender" v-model="selectedGender" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                    </select>
                </div>
            </div>
        </div>
        <label for="" class="mt-3">Relationship with user<span class="star">*</span></label>
        <div class="col-6 ">
            <select class="form-control" v-model="relation" v-on:keyup.enter="isFormFamilyInvalid ? saveFamilyData() : null">
                <option value="1">Select</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="wife">Wife</option>
                <option value="husband">Husband</option>

            </select>
        </div>
        </div>
        <div class="row my-3">
            <div class="col-5 mb-0 col-md-3 col-lg-3">
                <button class="btn btn-blue-color text-white" :disabled="!isFormFamilyInvalid" @click="saveFamilyData()">Add</button>
            </div>
        </div>
        <!-- <hr class="hr-border" /> -->
    </div>
            <div class="icon-select-img" v-if="!closeAddPatient">
              <p>Select a person to view his/her records</p>
            </div>
            <div class="hr-border"></div>
            <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

            <div class="row" style="padding-right: 10px;">
              <div class="col-8">
                <div class="mt-3" v-if="selectUserRecord" v-for="(value, index) in userRecord" :key="index">
                  <h3 class="medical-record">Medical records of  {{ value.firstName }} </h3>
                  <h3 class="medical-record">RxIx ID : {{ value.id }}-{{ 1 }}</h3>
                </div>

                <div class="mt-3" v-if="selectFamilyRecord">
                  <h3 class="medical-record">Medical records of  {{ selectFamilyRecord.firstName }}</h3>
                  <h3 class="medical-record">RxIx ID : {{ selectFamilyRecord.users.id }}-{{ 1+selectFamilyRecord.memberCount }}</h3>
                </div>
              </div>
              <div class="col-2 mt-3" v-if="selectUserRecord" v-for="(value, index) in userRecord" :key="index">
                <router-link :to="'/user/upload-record/' + value.id + '/' + value.role"> <img src="~@/assets/img/add-file.png"
                   class="patient-record-img" /></router-link>
                <p class="text-side-align d-none d-lg-block">Upload records</p>
              </div>
              <div class="col-2 mt-3" v-if="selectUserRecord" v-for="(value, index) in userRecord" :key="index">
                <router-link :to="'/user/manage-access/' + value.id + '/' + value.role"><img src="~@/assets/img/business.png"
                     class="patient-record-img" /></router-link>
                <p class="text-side-align d-none d-lg-block">Manage access</p>
              </div>
              <div class="col-2 mt-3" v-if="selectFamilyRecord">
                <router-link :to="'/user/upload-record/' + selectFamilyRecord.id + '/' + selectFamilyRecord.role"> <img src="~@/assets/img/add-file.png" 
                    class="patient-record-img" /></router-link>
                <p class="text-side-align d-none d-lg-block">Upload records</p>
              </div>
              <div class="col-2 mt-3" v-if="selectFamilyRecord">
                <router-link :to="'/user/manage-access/' + selectFamilyRecord.id + '/' + selectFamilyRecord.role"><img src="~@/assets/img/business.png" 
                    class="patient-record-img" /></router-link>
                <p class="text-side-align d-none d-lg-block">Manage access</p>
              </div>
            </div>
            <div v-if="selectUserRecord">
              <patientRecord :upValue="upValue" :key="selectUserRecord.id" :getUserRecord="uploadUserRecord"></patientRecord>
            </div>
            <div v-if="selectFamilyRecord">
              <patientFamilyRecord v-model="selectFamilyRecord" :upFamily="upFamily" :key="upFamily.id" :getFamilyRecord = "uploadFamilyRecord"></patientFamilyRecord>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <userfooter></userfooter>
</template>
<script>
import patientRecord from './patient-record/patient-record.vue'
import patientFamilyRecord from './patient-record/patient-familyrecord.vue'
import Family from './records.vue'
import Others from './records-share.vue'
import axios from 'axios';
import userfooter from "../user-footer.vue"
export default {
  components: {
    patientRecord,
    patientFamilyRecord,
    Family,
    Others,
    userfooter
  },
  data() {
    return {
      userRecords: {},
      userRecord: {},
      familyRecords: [],
      selectUserRecord: null,
      selectFamilyRecord: null,
      familygroupuser: false,
      famData:[],
      upFamily:{},
      getUser:{},
      activeConsultationDate:false,
      uploadUserRecord:{},
      uploadFamilyRecord:{},
      closeAddPatient:false,
      firstName:'',
      lastName:'',
      age:'',
      selectedGender:'',
      relation:'',
    };
    
  },
  created: async function () {
    this.recordsData();
    await this.getUserDetail();
    this.getUser = this.$route.query
    if(this.getUser.userFamData){
      const userConsultations = JSON.parse(this.getUser.userFamData)
      this.getUser.userRecords=userConsultations
    }
    if(this.getUser.role==='user'){
      this.openUserRecords(this.getUser);
    }else if(this.getUser.role==='family'){
      this.openFamilyRecords(this.getUser);
    }
  },
  computed:{
  isFormFamilyInvalid() {
    const nameRegex = /^[A-Za-z.\s]+$/;
    return (
        nameRegex.test(this.firstName) &&
        nameRegex.test(this.lastName) &&
        this.age <= 999 &&
        this.selectedGender !== "1" && 
        this.selectedGender .trim() !== ""&&
        this.relation !== "1" && 
        this.relation.trim() !== ""
    );
  }
},
  methods: {
    addPatient(){
      this.closeAddPatient=true
      this.selectUserRecord=false
      this.selectFamilyRecord=false
    },
    changePublicFirstName(){
      let name =this.firstName.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    changePublicLastName(){
      let name =this.lastName.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    async saveFamilyData() {   
    let familyPayload = {
        firstName: this.changePublicFirstName(),
        lastName: this.changePublicLastName(),
        age: this.age,
        gender: this.selectedGender,
        relation: this.relation,
        users: this.userRecords,
        role: 'family',
    }
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
        .post(`${BASE_API_URL}/family-member/${this.userRecords.id}`, familyPayload, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
      if (res?.data) {
          this.$swal("Added")
          this.closeAddPatient = false
          this.recordsData();

            }
        })
        .catch(function (error) {
          return error
        });
        },
    recordsData() {
      this.publicUserID = localStorage.getItem('publicSignUpData');
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/public-user/${this.publicUserID}/one`)
        .then((response) => {
          if (response?.data) {
            this.userRecords = response.data;   
          }
        })
    axios
    .get(`${BASE_API_URL}/family-member/${this.publicUserID}`)
    .then((response) => {
      this.famData=[]
      if (response?.data) {
        this.familyRecords = response.data;
        response.data?.map((data) => {
          this.FamilyUserId = data.users.id;
          if (this.FamilyUserId == this.publicUserID) {
            this.famData.push(data)
          }
        })
      }
    })
    },
    async getUserDetail() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.publicUserID = localStorage.getItem('publicSignUpData');
  await axios
    .get(`${BASE_API_URL}/public-user/${this.publicUserID}/one`)
    .then((response) => {
        if (response?.data) {
            this.userRecord = [response.data];
            this.UserRecordId = response.data?.id;
            this.communicationStatus = this.userRecord?.communication?.id
            this.Gender = this.userRecord?.gender?.gender
            this.primaryRadio = true
        }
        })
        .catch(function (error) {
          return error
        });
  },
    async openUserRecords(value) {
      this.closeAddPatient=false
      if(value.isUserLayout && value.role === 'user'){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
    .get(`${BASE_API_URL}/upload-records`)
    .then((response) => {
        response.data.map((filterData)=>{
  if((filterData?.user_id.id === value.userRecords.id )&& (filterData?.family_id?.id === this.upFamily?.id )&& (filterData.createdAt === value.createdAt)){
    this.uploadUserRecord={
      caseSheet: filterData.caseSheet,
      consultation_date:filterData.consultation_date,
      createdAt:filterData.createdAt,
      date:filterData.date,
      doctor_id:filterData.doctor_id,
      doctor_name:filterData.doctor_name,
      family_id:filterData.family_id,
      id:filterData.id,
      mobile:filterData.mobile,
      updateAt:filterData.updateAt,
      upload_prescription:filterData.upload_prescription,
      user_id:filterData.user_id
    }
    }
    })
    })
    .catch(function (error) {
        return error
    });
    this.upValue = value.userRecords
    this.selectFamilyRecord = false
    this.selectUserRecord=value.userRecords
    this.activeConsultationDate= true
  }else{
    this.uploadUserRecord=null
    this.uploadFamilyRecord=null
    this.upValue = value;
    this.selectFamilyRecord = false
    this.selectUserRecord = value
  }
    },
    async openFamilyRecords(value) {
      if(value.isUserLayout && value.role === 'family'){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
      .get(`${BASE_API_URL}/upload-records`)
      .then((response) => {
          response.data.map((filterData)=>{
        if((filterData?.family_id?.id=== value?.userRecords?.id) &&(filterData.createdAt === value.createdAt)){
          this.uploadFamilyRecord={
          caseSheet: filterData.caseSheet,
          consultation_date:filterData.consultation_date,
          createdAt:filterData.createdAt,
          date:filterData.date,
          doctor_id:filterData.doctor_id,
          doctor_name:filterData.doctor_name,
          family_id:filterData.family_id,
          id:filterData.id,
          mobile:filterData.mobile,
          updateAt:filterData.updateAt,
          upload_prescription:filterData.upload_prescription,
          user_id:filterData.user_id
      }
      }
        })
          })
          .catch(function (error) {
             return error
          });
      this.upFamily = value.userRecords
      this.selectUserRecord = false
      this.selectFamilyRecord= value.userRecords
      
      }
      else{
        this.uploadFamilyRecord=null
        this.uploadUserRecord=null
        this.upFamily = value;
      this.selectUserRecord = false
      this.selectFamilyRecord = value;
      }      
    }
  },
}
</script>
<style>
.form-control {
    border-color: #34989f;
}
.star {
    color: red;
    font-size: 20px;
}
.myfamilyTab {
  padding: 15px;
  margin-bottom: 0px;
}
.patient-record-img {
  height: 50px;
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

p.text-side-align {
  text-align: center;
}

.icon-select-img {
  margin-left: 20px;
  margin-top: 20px;
}

.image-fix {
  border: 3px solid #00979e;
  height: 100%;
  padding: 5px;
  cursor: pointer;
}

h3.medical-record {
  margin-left: 10px;
}

.text-center.name-fix {
  text-align: center !important;
  font-size: large;
  font-weight: bold;
}

img.img-camera.icon {
  float: right;
  width: 30px;
  margin: 5px;

}

img.img-men.icon {
  width: 50%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

img.img-women.icon {
  width: 50%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

/* .nav.nav-tabs {
  padding-top: 15px;
} */

.col-sm-12.usersStyle {
  margin: 0px 0px 0px 10px;
  background-color: #ffff;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%) ;

}

.col-sm-12.mx-auto.div-space {
  margin-bottom: 30px;
}

.left-space-desktop {
  margin-left: 20px;
}

.text-border {
  background-color: #00979e;
  height: 4px;
  /* margin-bottom: 20px; */
}

.nav-link {
  display: block;
  padding: 9px;
  margin: 5px;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-link.active {
  color: white;
  background-color: #00979e;
  border-color: #dee2e6 #dee2e6 #fff;
  padding: 9px;
  margin: 5px;
}
@media screen and (max-width:991px) {
  .col-sm-12.usersStyle{
    margin:auto;
    background-color: #ffff;
    border-radius: none;
    padding-bottom: 15px;
    box-shadow:none;
  }
  .nav.nav-tabs{
    padding-top: 0px;
  }
  .left-space-desktop {
    margin-left: 0px;
  }
}
@media screen and (max-width:576px) {
  p.text-side-align {
    font-size:10px;
    margin-bottom: 0px;
  }
  h3.medical-record {
    font-size: 20px;
  }
  .text-center.name-fix {
    font-size: 15px;
  }
}
@media screen and (max-width:430px) {
  .patient-record-img {
    height: 30px;
    width: 30px;
  }
}
</style>